import React from "react"
import s from "../PrivacyPolicyTab/PrivacyPolicyTab.module.scss"
import AccordionItem from "../../../../Popup/PopupBody/components/AccordionItem/AccordionItem"
import Accordion from "../../../../Popup/PopupBody/components/Accordion/Accordion"

const PrivacyPolicyTab = (props) => {
  const { currentLocale, translations } = props

  return (
    <div className={s.privacyPolicyTab}>
      <div className={s.privacyPolicyTabHeader}>
        <h2>{translations.popupTabLabelPrivacyPolicy}</h2>
        <p>
          {translations.popupPrivacyPolicyDescriptionFirst}
          <strong>{translations.popupPrivacyPolicyDescriptionSecond}</strong>
          {translations.popupPrivacyPolicyDescriptionThird}
          <strong>{translations.popupPrivacyPolicyDescriptionFourth}</strong>
          {translations.popupPrivacyPolicyDescriptionFifth}
        </p>
        <p>{translations.popupPrivacyPolicyDescriptionSixth}</p>
        <ul>
          <li>
            <p>
              {translations.popupPrivacyPolicyDescriptionSeventh}{" "}
              <a href={`mailto:${translations.popupPrivacyPolicyLinkFirst}`}>
                {translations.popupPrivacyPolicyLinkFirst}
              </a>
            </p>
          </li>
          <li>
            <p>
              {translations.popupPrivacyPolicyDescriptionEighth}{" "}
              <a href={`mailto:${translations.popupPrivacyPolicyLinkSecond}`}>
                {translations.popupPrivacyPolicyLinkSecond}
              </a>
            </p>
          </li>
        </ul>
        <p>{translations.popupPrivacyPolicyDescriptionNinth}</p>
        <p>{translations.popupPrivacyPolicyDescriptionTenth}</p>
        <p>{translations.popupPrivacyPolicyDescriptionEleventh}</p>
        <p>{translations.popupPrivacyPolicyDescriptionTwelfth}</p>
      </div>
      <Accordion>
        <AccordionItem
          id="privacy-policy-1"
          label={translations.popupPrivacyPolicyAccordionFirstLabel}
        >
          <div className={s.accordionItemBodyFirst}>
            <h3>{translations.popupPrivacyPolicyAccordionFirstController}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  translations.popupPrivacyPolicyAccordionFirstControllerDescription,
              }}
            />
          </div>
          <div className={s.accordionItemBodySecond}>
            <h3>{translations.popupPrivacyPolicyAccordionSecondLabel}</h3>
            <p>
              {translations.popupPrivacyPolicyAccordionSecondDescriptionFirst}
            </p>
            <ul>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSecondDescriptionSecond
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSecondDescriptionThird
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSecondDescriptionFourth
                  }
                </p>
              </li>
            </ul>
          </div>
          <div className={s.accordionItemBodyThird}>
            <h3>{translations.popupPrivacyPolicyAccordionThirdLabel}</h3>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionFirst}
              <strong>
                {translations.popupPrivacyPolicyAccordionThirdDescriptionSecond}
              </strong>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionThird}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionFourth}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionFifth}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionSixth}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionSeventh}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionThirdDescriptionEighth}{" "}
              <a
                href={`mailto:${translations.popupPrivacyPolicyAccordionThirdDescriptionLink}`}
              >
                {translations.popupPrivacyPolicyAccordionThirdDescriptionLink}
              </a>
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-2"
          label={translations.popupPrivacyPolicyAccordionFourthLabel}
        >
          <div className={s.accordionItemBodyFourth}>
            <h3>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionFirst}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionSecond}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionThird}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionFourth}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionFifth}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionSixth}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionSeventh}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionEighth}{" "}
              <a
                href={`mailto:${translations.popupPrivacyPolicyAccordionFourthDescriptionLink}`}
              >
                {translations.popupPrivacyPolicyAccordionFourthDescriptionLink}
              </a>
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionNinth}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionTenth}
            </p>
            <h3>
              {
                translations.popupPrivacyPolicyAccordionFourthDescriptionEleventh
              }
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionFourthDescriptionTwelfth}
            </p>
            <h3>
              {
                translations.popupPrivacyPolicyAccordionFourthDescriptionThirteenth
              }
            </h3>
            <p>
              {
                translations.popupPrivacyPolicyAccordionFourthDescriptionFourteenth
              }
            </p>
            <h3>
              {
                translations.popupPrivacyPolicyAccordionFourthDescriptionFifteenth
              }
            </h3>
            <p>
              {
                translations.popupPrivacyPolicyAccordionFourthDescriptionSixteenth
              }
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-3"
          label={translations.popupPrivacyPolicyAccordionFifthLabel}
        >
          <div className={s.accordionItemBodyFifth}>
            <p>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionFirst}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionSecond}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionThird}
            </p>
            <p>
              <strong>
                {translations.popupPrivacyPolicyAccordionFifthDescriptionFourth}
              </strong>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionFifth}{" "}
              <a
                href={`mailto:${translations.popupPrivacyPolicyAccordionFifthDescriptionLink}`}
              >
                {translations.popupPrivacyPolicyAccordionFifthDescriptionLink}
              </a>
            </p>
            <p>
              <strong>
                {translations.popupPrivacyPolicyAccordionFifthDescriptionSixth}
              </strong>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionSeventh}
            </p>
            <p>
              <strong>
                {translations.popupPrivacyPolicyAccordionFifthDescriptionEighth}
              </strong>
              {currentLocale !== "ru" ? (
                <>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionNinth
                  }{" "}
                  <a
                    href={`mailto:${translations.popupPrivacyPolicyAccordionFifthDescriptionLink}`}
                  >
                    {
                      translations.popupPrivacyPolicyAccordionFifthDescriptionLink
                    }
                  </a>
                </>
              ) : (
                <>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionNinthFirst
                  }
                  <a
                    href={`mailto:${translations.popupPrivacyPolicyAccordionFifthDescriptionLink}`}
                  >
                    {
                      translations.popupPrivacyPolicyAccordionFifthDescriptionLink
                    }
                  </a>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionNinthSecond
                  }
                </>
              )}
            </p>
            <p>
              <strong>
                {translations.popupPrivacyPolicyAccordionFifthDescriptionTenth}
              </strong>
              {translations.popupPrivacyPolicyAccordionFifthDescriptionEleventh}
            </p>
            <ul>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionTwelfth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionThirteenth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionFourteenth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionFifteenth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionFifthDescriptionSixteenth
                  }
                </p>
              </li>
            </ul>
            <p>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionSeventeenth
              }
            </p>
            <p>
              <strong>
                {
                  translations.popupPrivacyPolicyAccordionFifthDescriptionEighteenth
                }
              </strong>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionNineteenth
              }
            </p>
            <p>
              <strong>
                {
                  translations.popupPrivacyPolicyAccordionFifthDescriptionTwentieth
                }
              </strong>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyFirst
              }
            </p>
            <p>
              <strong>
                {
                  translations.popupPrivacyPolicyAccordionFifthDescriptionTwentySecond
                }
              </strong>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyThird
              }
            </p>
            <p>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyFourth
              }
            </p>
            <p>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyFifth
              }
            </p>
            <p>
              <strong>
                {
                  translations.popupPrivacyPolicyAccordionFifthDescriptionTwentySixth
                }
              </strong>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentySeventh
              }
            </p>
            <p>
              <strong>
                {
                  translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyEighth
                }
              </strong>
              {
                translations.popupPrivacyPolicyAccordionFifthDescriptionTwentyNinth
              }
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-4"
          label={translations.popupPrivacyPolicyAccordionSixthLabel}
        >
          <div className={s.accordionItemBodySixth}>
            <p>
              {translations.popupPrivacyPolicyAccordionSixthDescriptionFirst}
            </p>
            <ul>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionSecond
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionThird
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionFourth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionFifth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionSixth
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionSeventh
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSixthDescriptionEighth
                  }
                </p>
              </li>
              {currentLocale === "en" && (
                <li>
                  <p>
                    {
                      translations.popupPrivacyPolicyAccordionSixthDescriptionNinth
                    }
                  </p>
                </li>
              )}

              {currentLocale === "lv" && (
                <>
                  <li>
                    <p>
                      {
                        translations.popupPrivacyPolicyAccordionSixthDescriptionNinth
                      }
                    </p>
                  </li>
                  <li>
                    <p>
                      {
                        translations.popupPrivacyPolicyAccordionSixthDescriptionTenth
                      }
                    </p>
                  </li>
                </>
              )}
            </ul>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-5"
          label={translations.popupPrivacyPolicyAccordionSeventhLabel}
        >
          <div className={s.accordionItemBodySeventh}>
            <p>
              {translations.popupPrivacyPolicyAccordionSeventhDescriptionFirst}
            </p>
            <ul>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSeventhDescriptionSecond
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSeventhDescriptionThird
                  }
                </p>
              </li>
              <li>
                <p>
                  {
                    translations.popupPrivacyPolicyAccordionSeventhDescriptionFourth
                  }
                </p>
              </li>
              {currentLocale === "lt" && (
                <>
                  <li>
                    <p>
                      {
                        translations.popupPrivacyPolicyAccordionSeventhDescriptionFifth
                      }
                    </p>
                  </li>
                  <li>
                    <p>
                      {
                        translations.popupPrivacyPolicyAccordionSeventhDescriptionSixth
                      }
                    </p>
                  </li>
                </>
              )}
            </ul>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-6"
          label={translations.popupPrivacyPolicyAccordionEighthLabel}
        >
          <div className={s.accordionItemBodyEighth}>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionFirst}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionSecond}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionThird}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionFourth}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionFifth}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionSixth}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionSeventh}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionEighth}
            </p>
            <h3>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionNinth}
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionTenth}
            </p>
            <h3>
              {
                translations.popupPrivacyPolicyAccordionEighthDescriptionEleventh
              }
            </h3>
            <p>
              {translations.popupPrivacyPolicyAccordionEighthDescriptionTwelfth}
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-7"
          label={translations.popupPrivacyPolicyAccordionNinthLabel}
        >
          <div className={s.accordionItemBodyNinth}>
            <p>
              {translations.popupPrivacyPolicyAccordionNinthDescriptionFirst}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionNinthDescriptionSecond}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionNinthDescriptionThird}
            </p>
            {currentLocale !== "lt" && currentLocale !== "lv" && (
              <p>
                {translations.popupPrivacyPolicyAccordionNinthDescriptionFourth}
              </p>
            )}
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-8"
          label={translations.popupPrivacyPolicyAccordionTenthLabel}
        >
          <div className={s.accordionItemBodyTenth}>
            <p>
              {translations.popupPrivacyPolicyAccordionTenthDescriptionFirst}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionTenthDescriptionSecond}
            </p>
            <p>
              {translations.popupPrivacyPolicyAccordionTenthDescriptionThird}
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-9"
          label={translations.popupPrivacyPolicyAccordionEleventhLabel}
        >
          <div className={s.accordionItemBodyEleventh}>
            <p>
              {translations.popupPrivacyPolicyAccordionEleventhDescriptionFirst}
            </p>
            <p>
              {
                translations.popupPrivacyPolicyAccordionEleventhDescriptionSecond
              }
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="privacy-policy-10"
          label={translations.popupPrivacyPolicyAccordionTwelfthLabel}
        >
          <p>
            {translations.popupPrivacyPolicyAccordionTwelfthDescriptionFirst}
          </p>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default PrivacyPolicyTab
