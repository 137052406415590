import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import OgImageEe from "../images/og-image/og-image-ee.png"

export default function SEO({
  description = "",
  lang = "ee",
  meta = [],
  title,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description
  const metaSiteUrl = site.siteMetadata.siteUrl
  const metaImage = site.siteMetadata.siteUrl.concat(OgImageEe)

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[{ rel: "icon", type: "image/png", href: "/favicons/favicon.png" }]}
      meta={[
        { name: "description", content: metaDescription },
        { property: "og:description", content: metaDescription },
        { property: "og:image", name: "image", content: metaImage },
        {
          property: "og:title",
          content: `${title} | ${site.siteMetadata.title}`,
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: metaSiteUrl },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:creator", content: site.siteMetadata.author },
        { name: "twitter:description", content: metaDescription },
        { name: "twitter:image", content: metaImage },
        { name: "twitter:title", content: title },
      ].concat(meta)}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
    >
      {metaSiteUrl === "https://www.burgerking.ee" && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://analytics.burgerking.ee/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KSG773B');`}
        </script>
      )}
      {metaSiteUrl === "https://www.burgerking.lt" && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://analytics.burgerking.lt/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KSG773B');`}
        </script>
      )}
      {metaSiteUrl === "https://www.burgerking.lv" && (
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://analytics.burgerking.lv/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KSG773B');`}
        </script>
      )}
    </Helmet>
  )
}
