import React from "react"
import s from "../CookieType/CookieType.module.scss"
import { CookieIcon } from "../../../../../../../common/Icons"

const CookieType = (props) => {
  const { label, description } = props

  return (
    <div className={s.cookieType}>
      <div className={s.container}>
        <CookieIcon className={s.icon} />
        <h3 className={s.label}>{label}</h3>
      </div>
      <p className={s.description}>{description}</p>
    </div>
  )
}

export default CookieType
