import React from "react"

import { BurgerKingLogoIcon } from "../common/Icons"
import LocalizedLink from "../common/LocalizedLink"
import s from "./BurgerKingLogo.module.scss"

export default function BurgerKingLogo({ className, siteTitle }) {
  return (
    <LocalizedLink aria-label={siteTitle} className={className} to="/">
      <BurgerKingLogoIcon className={s.logo} />
      <div className={s.circle}>
        <div className={s.arc} />
      </div>
    </LocalizedLink>
  )
}
