import { isBrowser } from "./env"

const isProductionDomain = () => {
  return isBrowser() && window.location.hostname.includes("burgerking.")
}

export const isCookieAccepted = () => {
  return (
    isBrowser() && document.cookie.includes("tlnk_bkw=necessary_cookies=true")
  )
}

export const setCookies = (chosenCookies) => {
  let expires = new Date()

  expires.setTime(expires.getTime() + 31536000000)

  let cookie = "tlnk_bkw=necessary_cookies=true"

  if (chosenCookies.preferences) {
    cookie += ",preferences=true"
  }

  if (chosenCookies) {
    if (chosenCookies.statisticsAnalyticsCookies) {
      cookie += ",statistics_and_analytics=true"
    }

    if (chosenCookies.marketingCookies) {
      cookie += ",marketing_cookies=true"
    }
  } else {
    cookie +=
      ",preferences=true,statistics_and_analytics=true,marketing_cookies=true"
  }

  cookie += `${
    isProductionDomain() ? `;domain=${window.location.hostname}` : ""
  };path=/;expires=${expires.toUTCString()}`
  document.cookie = cookie
}

export const setEvent = (name) => {
  const event = new Event(name)

  dispatchEvent(event)
}

export const getCookies = () => {
  const bkwCookie =
    isBrowser() &&
    document.cookie.replace(
      /(?:(?:^|.*;\s*)tlnk_bkw=\s*\s*([^;]*).*$)|^.*$/,
      "$1"
    )

  if (bkwCookie) {
    const cookieValues = bkwCookie.split(",")

    const newCookie = {}

    cookieValues.map((item) => {
      let cookie = item.split("=")

      newCookie[cookie[0]] = Boolean(cookie[1])

      return cookie
    })

    return newCookie
  }
  return {}
}
