import React from "react"
import s from "../CookieTable/CookieTable.module.scss"

const CookieTable = (props) => {
  const { translations } = props

  return (
    <div className={s.cookieTable}>
      {translations.popupCookiePolicyAccordionFifthCookieTableCookiesList.map(
        (item, index) => {
          const { description, expiration, name, service } = item

          return (
            <React.Fragment key={index}>
              <h4>
                {
                  translations.popupCookiePolicyAccordionFifthCookieTableServiceLabel
                }
              </h4>
              <p>{service}</p>
              <h4>
                {
                  translations.popupCookiePolicyAccordionFifthCookieTableCookieLabel
                }
              </h4>
              <p>{name}</p>
              {description !== "" && (
                <>
                  <h4>
                    {
                      translations.popupCookiePolicyAccordionFifthCookieTableDescriptionLabel
                    }
                  </h4>
                  <p>{description}</p>
                </>
              )}
              {expiration !== "" && (
                <>
                  <h4>
                    {
                      translations.popupCookiePolicyAccordionFifthCookieTableExpirationLabel
                    }
                  </h4>
                  <p>{expiration}</p>
                </>
              )}
              <div className={s.divider}></div>
            </React.Fragment>
          )
        }
      )}
    </div>
  )
}

export default CookieTable
