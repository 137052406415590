import { Link } from "gatsby"
import React from "react"

import s from "./LocaleLinks.module.scss"

export default function LocaleLinks({
  langCode,
  locales,
  pagePathsByLangCode,
}) {
  const langByLangCode = locales.find((locale) => locale.langCode === langCode)

  return (
    <div className={s.localeLinks}>
      <ul>
        {[...locales].map(({ label, langCode }) => (
          <li key={label}>
            <Link
              className={langCode === langByLangCode.langCode ? s.active : ""}
              to={pagePathsByLangCode[langCode]}
            >
              {label}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
