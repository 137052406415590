import classNames from "classnames"
import { navigate } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import { ScrollLocky } from "react-scroll-locky"
import { ArrowBottomIcon } from "../common/Icons"
import LocalizedLink from "../common/LocalizedLink"
import { LocaleContext } from "../Layout"
import SearchBar, { variations } from "../SearchBar/SearchBar"
import BurgerKingLogo from "./BurgerKingLogo"
import HamburgerButton from "./HamburgerButton"
import s from "./Header.module.scss"
import LocaleDropdown from "./LocaleDropdown"
import LocaleLinks from "./LocaleLinks"

export default function Header({
  langCode,
  pagePathsByLangCode,
  searchPageUrl,
  siteTitle,
}) {
  const [searchValue, setSearchValue] = useState("")
  const { header, ui } = useContext(LocaleContext)
  const [fullScreenOpen, setFullScreenOpen] = useState(false)
  const headerRef = useRef(null)
  const headerClasses = classNames(s.header, {
    [s.fullScreenOpen]: fullScreenOpen,
  })
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isDesktop, setIsDesktop] = useState(null)
  const [isMobile, setIsMobile] = useState(null)

  const testIsDesktop = () => window.innerWidth >= 1200
  const testIsMobile = () => window.innerWidth <= 992

  const handleResize = () => {
    setIsDesktop(testIsDesktop())
    setIsMobile(testIsMobile())
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const Nav = ({ menuItems }) => {
    const renderMenuItems = (items) =>
      items.map(({ description, url }) => (
        <li key={description}>
          <LocalizedLink
            activeClassName={s.active}
            className={langCode === "ru" ? s.cyrillic : null}
            partiallyActive={true}
            to={url}
          >
            {description}
          </LocalizedLink>
        </li>
      ))

    const renderDropdown = () => (
      <li className={s.dropdown}>
        <a onClick={() => setIsDropdownOpen(!isDropdownOpen)} role="button">
          {header.dropdownTitle}
          <ArrowBottomIcon
            className={classNames(s.close, { [s.active]: isDropdownOpen })}
          />
        </a>
        {isDropdownOpen && (
          <ul className={s.additionalMenuItems}>
            {renderMenuItems(menuItems.slice(3))}
          </ul>
        )}
      </li>
    )

    const shouldShowDropdown = () =>
      (menuItems.length >= 5 && !isDesktop && !isMobile) ||
      menuItems.length >= 6

    return (
      <nav>
        <ul className={s.menuItems}>
          {renderMenuItems(
            shouldShowDropdown() ? menuItems.slice(0, 3) : menuItems
          )}
          {shouldShowDropdown() && renderDropdown()}
        </ul>
      </nav>
    )
  }

  return (
    <div className={s.headerHolder}>
      <header className={headerClasses} ref={headerRef}>
        <div className={s.content}>
          <HamburgerButton onClick={() => setFullScreenOpen(true)} />
          <div className={s.logoShadow} />
          <BurgerKingLogo className={s.logo} siteTitle={siteTitle} />
          <Nav menuItems={header.links} />
          <div className={s.rightColumn}>
            <LocaleDropdown
              className={s.localeDropdown}
              langCode={langCode}
              locales={header.locales}
              pagePathsByLangCode={pagePathsByLangCode}
            />
            <SearchBar
              onChange={(e) => setSearchValue(e.target.value)}
              onSearchClick={redirectToSearchPage}
              placeholder={ui.searchFieldPlaceholder}
              value={searchValue}
              variation={variations.header}
            />
          </div>
        </div>
      </header>
      <ScrollLocky enabled={fullScreenOpen}>
        <div
          className={classNames(s.fullScreenNavigation, {
            [s.fullScreenOpen]: fullScreenOpen,
          })}
        >
          <CloseButton onClick={() => setFullScreenOpen(false)} />
          <Nav menuItems={header.links} />
          <LocaleLinks
            langCode={langCode}
            locales={header.locales}
            pagePathsByLangCode={pagePathsByLangCode}
          />
        </div>
      </ScrollLocky>
    </div>
  )

  function redirectToSearchPage(searchQuery) {
    return navigate(searchPageUrl + searchQuery)
  }
}

function CloseButton({ onClick }) {
  return (
    <div
      className={s.closeButton}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      ×
    </div>
  )
}
