import classNames from "classnames"
import { Link } from "gatsby"
import React, { useRef, useState } from "react"
import { useClickAway } from "react-use"

import { CheckMarkIcon, DropdownArrowIcon } from "../common/Icons"
import s from "./LocaleDropdown.module.scss"

export default function LocaleDropdown({
  className,
  langCode,
  locales,
  pagePathsByLangCode,
}) {
  const [open, setOpen] = useState(false)
  const langByLangCode = locales.find((locale) => locale.langCode === langCode)
  const [currentLang, setCurrentLang] = useState(langByLangCode)
  const offClickContainerRef = useRef()

  const changeLang = (langCode) => {
    setCurrentLang(langCode)
    setOpen(false)
  }

  useClickAway(offClickContainerRef, () => setOpen(false))

  return (
    <div
      className={classNames(s.localeDropdown, className)}
      ref={offClickContainerRef}
    >
      <button className={s.dropdownTrigger} onClick={() => setOpen(!open)}>
        {currentLang.label}{" "}
        <DropdownArrowIcon
          className={classNames(s.dropdownArrow, { [s.open]: open })}
        />
      </button>
      {open ? (
        <ul className={s.dropdownContent}>
          {locales.map(({ label, langCode }) => (
            <li
              className={classNames(
                s.langButton,
                { [s.cyrillic]: langCode === "ru" },
                { [s.disable]: langCode === currentLang.langCode }
              )}
              key={label}
            >
              <Link
                onClick={() => changeLang(langCode)}
                to={pagePathsByLangCode[langCode]}
              >
                {label}{" "}
                {langCode === currentLang.langCode ? (
                  <CheckMarkIcon className={s.dropdownCheck} />
                ) : null}
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
