import classNames from "classnames"
import React from "react"

import EdgeLineSvg from "../../../static/svg/decoration/edge-line.svg"
import s from "./Decorations.module.scss"

const withDiv = (Svg, ownClass) => (props) => (
  <div
    {...props}
    className={classNames(s.decoration, ownClass, props.className)}
  >
    <Svg />
  </div>
)

export const BottomEdgeLine = withDiv(EdgeLineSvg, s.bottomEdge)
export const TopEdgeLine = withDiv(EdgeLineSvg, s.topEdge)
