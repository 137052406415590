import React, { useState } from "react"
import s from "../Popup/Popup.module.scss"
import PopupBody from "./PopupBody/PopupBody"
import PopupFooter from "./PopupFooter/PopupFooter"
import PopupHeader from "./PopupHeader/PopupHeader"

const Popup = (props) => {
  const {
    checkedState,
    currentLocale,
    cookiesAll,
    cookiesSelection,
    langCode,
    marketingState,
    pagePathsByLangCode,
    preferencesState,
    preopenTab,
    setCheckedState,
    saveCookies,
    setMarketingCheckedState,
    setPreferencesState,
    translations,
    setCurrentLocale,
  } = props

  const [selectedTab, setSelectedTab] = useState(
    preopenTab ? preopenTab : "selection"
  )

  const selectTab = (tabName) => {
    setSelectedTab(tabName)
  }

  return (
    <section className={s.popup}>
      <PopupHeader
        currentLocale={currentLocale}
        langCode={langCode}
        pagePathsByLangCode={pagePathsByLangCode}
        setCurrentLocale={setCurrentLocale}
        translations={translations}
      />
      <PopupBody
        checkedState={checkedState}
        currentLocale={currentLocale}
        marketingState={marketingState}
        preferencesState={preferencesState}
        preopenTab={preopenTab}
        selectTab={selectTab}
        selectedTab={selectedTab}
        setCheckedState={setCheckedState}
        setMarketingCheckedState={setMarketingCheckedState}
        setPreferencesState={setPreferencesState}
        translations={translations}
      />
      {selectedTab === "selection" && (
        <PopupFooter
          cookiesAll={cookiesAll}
          cookiesSelection={cookiesSelection}
          saveCookies={saveCookies}
          translations={translations}
        />
      )}
    </section>
  )
}

export default Popup
