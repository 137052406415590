const { findProductById } = require("./products")

function createLocalizedPagePath(pagesTranslations, localePath, path) {
  const slug = findPageSlugByPath(pagesTranslations, path)
  const slugPath = isRoot(path) || !slug ? path : `/${slug}/`

  return localePath ? localePath + slugPath : slugPath
}

function findPageSlugByPath(pagesTranslations = {}, path = "") {
  const pageTranslations = findPageByPath(pagesTranslations, path)
  return pageTranslations && pageTranslations.slug
}

function findPageByPath(pagesTranslations = {}, path = "") {
  return Object.values(pagesTranslations).find(({ id }) => id === path)
}

function findProductGroupSlugById(productGroups = {}, groupId = "") {
  const productGroup = Object.values(productGroups).find(
    ({ id }) => id === groupId
  )
  return productGroup && productGroup.slug
}

function findProductSlugById(products = [], productId = "") {
  const product = findProductById(products, productId)
  return product && product.slug
}

function isRoot(path) {
  return path === "/"
}

function isExternal(url) {
  return url.startsWith("http://") || url.startsWith("https://") || isPdf(url)
}

function isPdf(url) {
  return url.endsWith(".pdf")
}

module.exports = {
  createLocalizedPagePath,
  findPageByPath,
  findPageSlugByPath,
  findProductGroupSlugById,
  findProductSlugById,
  isPdf,
  isExternal,
}
