import React, { useState } from "react"
import { CloseIcon, InfoIcon } from "../common/Icons"
import { BottomEdgeLine } from "../common/Decorations"
import s from "./Alert.module.scss"

const Alert = (props) => {
  const { translations } = props
  const [isVisible, setIsVisible] = useState(true)

  const handleClose = () => {
    setIsVisible(false)
  }

  return translations.isVisible && isVisible ? (
    <div className={s.containerFirst}>
      <div className={s.containerSecond}>
        <InfoIcon className={s.info} />
        <CloseIcon className={s.closeFirst} onClick={handleClose} />
      </div>
      <div className={s.containerThird}>
        <div className={s.containerFourth}>
          <h1>{translations.heading}</h1>
          <p>{translations.description}</p>
        </div>
        <div className={s.containerFifth}>
          <a href={translations.href} target="_blank" rel="noopener noreferrer">
            {translations.link}
          </a>
          <CloseIcon className={s.closeSecond} onClick={handleClose} />
        </div>
      </div>
      <BottomEdgeLine className={s.decoration} />
    </div>
  ) : null
}

export default Alert
