import React from "react"
import s from "../Banner/Banner.module.scss"
import BannerFooter from "./BannerFooter/BannerFooter"
import BannerHeader from "./BannerHeader/BannerHeader"

const Banner = (props) => {
  const {
    currentLocale,
    cookiesAll,
    langCode,
    pagePathsByLangCode,
    saveCookies,
    setCurrentLocale,
    setPopupVisible,
    translations,
  } = props
  return (
    <section className={s.banner}>
      <BannerHeader
        currentLocale={currentLocale}
        translations={translations}
        langCode={langCode}
        pagePathsByLangCode={pagePathsByLangCode}
        setCurrentLocale={setCurrentLocale}
      />
      <BannerFooter
        cookiesAll={cookiesAll}
        saveCookies={saveCookies}
        setPopupVisible={setPopupVisible}
        translations={translations}
      />
    </section>
  )
}

export default Banner
