import React from "react"

import ArrowBottomSvg from "../../../static/svg/icons/arrow-bottom.svg"
import ArrowThinSvg from "../../../static/svg/icons/arrow-thin.svg"
import ArrowSvg from "../../../static/svg/icons/arrow.svg"
import BackSvg from "../../../static/svg/icons/back.svg"
import BurgerKingLogoSvg from "../../../static/svg/icons/burger-king-logo.svg"
import BurgerKingMarkerSvg from "../../../static/svg/icons/burger-king-map-marker.svg"
import CheckMarkSvg from "../../../static/svg/icons/check.svg"
import CheckMarkSvgNew from "../../../static/svg/icons/check-new.svg"
import CloseSvg from "../../../static/svg/icons/close.svg"
import CookToolsSvg from "../../../static/svg/icons/cook-tools.svg"
import CookieSvg from "../../../static/svg/icons/cookie.svg"
import CrownStandardSvg from "../../../static/svg/icons/crown-standard.svg"
import CrownSvg from "../../../static/svg/icons/crown.svg"
import DropdownArrowSvg from "../../../static/svg/icons/dropdown-arrow.svg"
import KingJrMealSvg from "../../../static/svg/icons/king-jr-meal-logo.svg"
import LikeSvg from "../../../static/svg/icons/like.svg"
import PlaySvg from "../../../static/svg/icons/play.svg"
import SearchSvg from "../../../static/svg/icons/search.svg"
import FacebookSvg from "../../../static/svg/icons/social-facebook.svg"
import InstagramSvg from "../../../static/svg/icons/social-instagram.svg"
import TagSvg from "../../../static/svg/icons/tag.svg"
import WordMarkSvg from "../../../static/svg/icons/wordmark.svg"
import inputFileSvg from "../../../static/svg/icons/input-file.svg"
import infoSvg from "../../../static/svg/icons/info.svg"

const withDiv = (Svg) => (props) => (
  <div {...props}>
    <Svg />
  </div>
)

export const ArrowIcon = withDiv(ArrowSvg)
export const ArrowBottomIcon = withDiv(ArrowBottomSvg)
export const ArrowThinIcon = withDiv(ArrowThinSvg)
export const BackIcon = BackSvg
export const BurgerKingLogoIcon = BurgerKingLogoSvg
export const BurgerKingMarkerIcon = BurgerKingMarkerSvg
export const CheckMarkIcon = CheckMarkSvg
export const CheckMarkIconNew = CheckMarkSvgNew
export const CloseIcon = withDiv(CloseSvg)
export const CookieIcon = withDiv(CookieSvg)
export const CookToolsIcon = withDiv(CookToolsSvg)
export const CrownIcon = withDiv(CrownSvg)
export const CrownStandardIcon = withDiv(CrownStandardSvg)
export const DropdownArrowIcon = DropdownArrowSvg
export const FacebookIcon = withDiv(FacebookSvg)
export const InstagramIcon = withDiv(InstagramSvg)
export const LikeIcon = withDiv(LikeSvg)
export const PlayIcon = withDiv(PlaySvg)
export const SearchIcon = withDiv(SearchSvg)
export const TagIcon = withDiv(TagSvg)
export const WordMark = withDiv(WordMarkSvg)
export const KingJrMealIcon = KingJrMealSvg
export const InputFileIcon = inputFileSvg
export const InfoIcon = infoSvg
