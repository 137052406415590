import React, { useEffect, useState } from "react"
import "../scss/layout.global.scss"

import classNames from "classnames"
import { graphql, useStaticQuery } from "gatsby"

import { createLocalizedPagePath } from "../utils/urls"
import { BottomEdgeLine } from "./common/Decorations"
import Footer from "./Footer"
import Header from "./Header/Header"
import CookieConsent from "./CookieConsent/CookieConsent"
import { isGTmetrix, isLighthouse } from "../utils/env"
import { isCookieAccepted } from "../utils/cookie"
import Alert from "./Alert/Alert"

const LocaleContext = React.createContext()
export { LocaleContext }

export default function Layout({
  i18n,
  children,
  className,
  style,
  langCode,
  productGroups,
  pagePathsByLangCode,
}) {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const [isCookieConsentEditOpen, setIsCookieConsentEditOpen] = useState(false)
  const [isWindowReady, setIsWindowReady] = useState(null)
  const [preopenTab, setPreopenTab] = useState(null)
  const searchPageUrl =
    createLocalizedPagePath(i18n.pages, i18n.localePath, "/search/") + "?q="

  useEffect(() => {
    setIsWindowReady(true)
  }, [])

  return (
    <LocaleContext.Provider value={i18n}>
      <div className="main">
        {!isLighthouse() &&
        !isGTmetrix() &&
        !isCookieAccepted() &&
        isWindowReady ? (
          <CookieConsent
            currentMarket={i18n.header.locales[0].langCode}
            langCode={langCode}
            pagePathsByLangCode={pagePathsByLangCode}
          />
        ) : (
          isWindowReady &&
          isCookieConsentEditOpen && (
            <CookieConsent
              currentMarket={i18n.header.locales[0].langCode}
              isCookieConsentEditOpen={isCookieConsentEditOpen}
              langCode={langCode}
              pagePathsByLangCode={pagePathsByLangCode}
              preopenTab={preopenTab}
              setIsCookieConsentEditOpen={setIsCookieConsentEditOpen}
            />
          )
        )}
        <Alert translations={i18n.alert} />
        <Header
          langCode={langCode}
          pagePathsByLangCode={pagePathsByLangCode}
          searchPageUrl={searchPageUrl}
          siteTitle={data.site.siteMetadata.title}
        />
        <div className={classNames("main-content", className)} style={style}>
          {children}
          <BottomEdgeLine className="footer-edge" />
        </div>
        <Footer
          langCode={langCode}
          productGroups={productGroups}
          setIsCookieConsentEditOpen={setIsCookieConsentEditOpen}
          setPreopenTab={setPreopenTab}
        />
      </div>
    </LocaleContext.Provider>
  )
}
