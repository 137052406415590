import classNames from "classnames"
import React, { useEffect, useState } from "react"
import Banner from "../CookieConsent/Banner/Banner"
import Popup from "../CookieConsent/Popup/Popup"
import s from "../CookieConsent/CookieConsent.module.scss"
import translations from "../../../content/translations/cookie-consent/translations.json"
import { getCookies, setCookies, setEvent } from "../../utils/cookie"

const CookieConsent = (props) => {
  const {
    currentMarket,
    isCookieConsentEditOpen,
    langCode,
    pagePathsByLangCode,
    preopenTab,
    setIsCookieConsentEditOpen,
  } = props

  const browserCookies = getCookies()

  const [checkedState, setCheckedState] = useState(
    browserCookies["statistics_and_analytics"]
      ? browserCookies["statistics_and_analytics"]
      : false
  )
  const [currentLocale, setCurrentLocale] = useState(langCode)
  const [cookiesAccepted, setCookiesAccepted] = useState(false)
  const [marketingState, setMarketingCheckedState] = useState(
    browserCookies["marketing_cookies"]
      ? browserCookies["marketing_cookies"]
      : false
  )
  const [popupVisible, setPopupVisible] = useState(false)
  const [preferencesState, setPreferencesState] = useState(
    browserCookies["preferences"] ? browserCookies["preferences"] : false
  )

  const translationsByLocale = translations[currentMarket][currentLocale]

  const cookiesAll = {
    necessaryCookies: true,
    preferences: true,
    statisticsAnalyticsCookies: true,
    marketingCookies: true,
  }

  const cookiesSelection = {
    necessaryCookies: true,
    preferences: preferencesState,
    statisticsAnalyticsCookies: checkedState,
    marketingCookies: marketingState,
  }

  const saveCookies = (chosenCookies) => {
    setCookies(chosenCookies)
    setEvent("burger-king-web-root-accept")
    setCookiesAccepted(true)
    isCookieConsentEditOpen && setIsCookieConsentEditOpen(false)
    document.getElementsByTagName("html")[0].classList.remove("open-popup")
  }

  useEffect(() => {
    setEvent("burger-king-web-root-loaded")
    document.getElementsByTagName("html")[0].classList.add("open-popup")
  }, [, isCookieConsentEditOpen])

  return !cookiesAccepted || isCookieConsentEditOpen ? (
    <div
      className={classNames(s.cookieConsentOverlay, {
        [s.cyrillic]: currentLocale === "ru",
      })}
    >
      <div className={s.flexContainer}>
        {popupVisible || preopenTab ? (
          <Popup
            checkedState={checkedState}
            cookiesAll={cookiesAll}
            cookiesSelection={cookiesSelection}
            currentLocale={currentLocale}
            langCode={langCode}
            marketingState={marketingState}
            pagePathsByLangCode={pagePathsByLangCode}
            preferencesState={preferencesState}
            preopenTab={preopenTab}
            saveCookies={saveCookies}
            setCheckedState={setCheckedState}
            setMarketingCheckedState={setMarketingCheckedState}
            setPreferencesState={setPreferencesState}
            translations={translationsByLocale}
            setCurrentLocale={setCurrentLocale}
          />
        ) : (
          <Banner
            currentLocale={currentLocale}
            cookiesAll={cookiesAll}
            langCode={langCode}
            pagePathsByLangCode={pagePathsByLangCode}
            saveCookies={saveCookies}
            setCurrentLocale={setCurrentLocale}
            setPopupVisible={setPopupVisible}
            translations={translationsByLocale}
          />
        )}
      </div>
    </div>
  ) : null
}

export default CookieConsent
