import React, { useState } from "react"
import s from "../Accordion/Accordion.module.scss"

const Accordion = (props) => {
  const { children } = props

  const childrenArray = React.Children.toArray(children)

  const [isOpen, setIsOpen] = useState(null)

  const handleChange = (id) => {
    isOpen === id ? setIsOpen(null) : setIsOpen(id)
  }

  return (
    <ul className={s.accordion}>
      {childrenArray.map((item) => {
        return React.cloneElement(item, {
          open: isOpen === item.props.id,
          onClick: () => handleChange(item.props.id),
        })
      })}
    </ul>
  )
}

export default Accordion
