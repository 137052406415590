import React from "react"
import s from "../AccordionItem/AccordionItem.module.scss"
import { ArrowBottomIcon } from "../../../../../common/Icons"

const AccordionItem = (props) => {
  const { children, id, label, onClick, open } = props

  return (
    <li className={s.accordionItem}>
      <div className={s.accordionItemHeader} id={id} onClick={onClick}>
        <span
          className={s.accordionItemHeaderLabel}
          id="cookie-consent-accordion-item-label"
        >
          {label}
        </span>
        <ArrowBottomIcon className={open ? s.iconOpen : ""} />
      </div>
      {open && <div className={s.accordionItemBody}>{children}</div>}
    </li>
  )
}

export default AccordionItem
