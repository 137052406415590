import React from "react"
import s from "../BannerFooter/BannerFooter.module.scss"

const BannerFooter = (props) => {
  const { cookiesAll, saveCookies, setPopupVisible, translations } = props

  return (
    <footer className={s.bannerFooter}>
      <div className={s.buttonsContainer}>
        <button
          className={s.buttonAgree}
          onClick={() => saveCookies(cookiesAll)}
        >
          <span>{translations.bannerButtonTitle}</span>
        </button>
        <button
          className={s.buttonChangeSettings}
          onClick={() => setPopupVisible(true)}
        >
          <span>{translations.bannerLinkTitle}</span>
        </button>
      </div>
    </footer>
  )
}

export default BannerFooter
