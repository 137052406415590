export function isBrowser() {
  return typeof window != "undefined" && !!window.document
}

export function isGTmetrix() {
  return isBrowser() && navigator.userAgent.indexOf("GTmetrix") !== -1
}

export function isLighthouse() {
  return (
    isBrowser() &&
    navigator?.userAgentData &&
    JSON.stringify(navigator.userAgentData).indexOf("Lighthouse") !== -1
  )
}
