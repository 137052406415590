import React from "react"

import s from "../BannerHeader/BannerHeader.module.scss"
import LocaleDropdown from "../BannerHeader/components/LocaleDropdown/LocaleDropdown"
import TitleWithImg from "../BannerHeader/components/TitleWithImg/TitleWithImg"

const BannerHeader = (props) => {
  const {
    currentLocale,
    langCode,
    pagePathsByLangCode,
    setCurrentLocale,
    translations,
  } = props

  return (
    <header className={s.bannerHeader}>
      <div className={s.bannerHeaderContainer}>
        <TitleWithImg translations={translations} />
        <LocaleDropdown
          currentLocale={currentLocale}
          langCode={langCode}
          pagePathsByLangCode={pagePathsByLangCode}
          setCurrentLocale={setCurrentLocale}
        />
      </div>
      <p className={s.paragraph}>{translations.bannerDescription}</p>
    </header>
  )
}

export default BannerHeader
