import { Link } from "gatsby"
import React, { useContext } from "react"

import { createLocalizedPagePath } from "../../utils/urls"
import { LocaleContext } from "../Layout"

export default function LocalizedLink({ to, ...props }) {
  const { localePath, pages } = useContext(LocaleContext)
  const path = createLocalizedPagePath(pages, localePath, to)

  return <Link {...props} to={path || "/"} />
}
