import React from "react"
import s from "../CookiePolicyTab/CookiePolicyTab.module.scss"
import AccordionItem from "../../../../Popup/PopupBody/components/AccordionItem/AccordionItem"
import Accordion from "../../../../Popup/PopupBody/components/Accordion/Accordion"
import CookieType from "../CookiePolicyTab/components/CookieType/CookieType"
import CookieTable from "./components/CookieTable/CookieTable"

const CookiePolicyTab = (props) => {
  const { translations } = props

  return (
    <div className={s.cookiePolicyTab}>
      <div className={s.cookiePolicyTabHeader}>
        <h2>{translations.popupTabLabelCookiePolicy}</h2>
        <p>
          {translations.popupCookiePolicyDescriptionFirst}
          <strong>{translations.popupCookiePolicyDescriptionSecond}</strong>
          {translations.popupCookiePolicyDescriptionThird}
          <strong>{translations.popupCookiePolicyDescriptionFourth}</strong>
          {translations.popupCookiePolicyDescriptionFifth}
        </p>
        <p>
          {translations.popupCookiePolicyDescriptionSixth}
          <a
            href={translations.popupCookiePolicyDescriptionLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {translations.popupCookiePolicyDescriptionSeventh}
          </a>
          .
        </p>
      </div>
      <Accordion>
        <AccordionItem
          id="cookie-policy-1"
          label={translations.popupCookiePolicyAccordionFirstLabel}
        >
          <div className={s.accordionItemBodyFirst}>
            <p>{translations.popupCookiePolicyAccordionFirstDescription}</p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="cookie-policy-2"
          label={translations.popupCookiePolicyAccordionSecondLabel}
        >
          <div className={s.accordionItemBodySecond}>
            <p>
              {translations.popupCookiePolicyAccordionSecondDescriptionFirst}
            </p>
            <p>
              {translations.popupCookiePolicyAccordionSecondDescriptionSecond}
            </p>
          </div>
        </AccordionItem>

        <AccordionItem
          id="cookie-policy-3"
          label={translations.popupCookiePolicyAccordionFourthLabel}
        >
          <div className={s.cookieTypesList}>
            <CookieType
              description={
                translations.popupCookiePolicyAccordionFourthCookieTypeFirstDescription
              }
              label={
                translations.popupCookiePolicyAccordionFourthCookieTypeFirstLabel
              }
            />
            <CookieType
              description={
                translations.popupCookiePolicyAccordionFourthCookieTypeSecondDescription
              }
              label={
                translations.popupCookiePolicyAccordionFourthCookieTypeSecondLabel
              }
            />
            <CookieType
              description={
                translations.popupCookiePolicyAccordionFourthCookieTypeThirdDescription
              }
              label={
                translations.popupCookiePolicyAccordionFourthCookieTypeThirdLabel
              }
            />
            <CookieType
              description={
                translations.popupCookiePolicyAccordionFourthCookieTypeFourthDescription
              }
              label={
                translations.popupCookiePolicyAccordionFourthCookieTypeFourthLabel
              }
            />
          </div>
        </AccordionItem>

        <AccordionItem
          id="cookie-policy-4"
          label={translations.popupCookiePolicyAccordionFifthLabel}
        >
          <CookieTable translations={translations} />
        </AccordionItem>

        <AccordionItem
          id="cookie-policy-5"
          label={translations.popupCookiePolicyAccordionSixthLabel}
        >
          <div className={s.accordionItemBodySixth}>
            <p>{translations.popupCookiePolicyAccordionSixthDescription}</p>
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default CookiePolicyTab
