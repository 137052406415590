import React from "react"
import CheckboxOption from "../CheckboxOption/CheckboxOption"
import s from "../SelectionTab/SelectionTab.module.scss"

const SelectionTab = (props) => {
  const {
    checkedState,
    marketingState,
    preferencesState,
    setCheckedState,
    setMarketingCheckedState,
    setPreferencesState,
    translations,
  } = props

  return (
    <div className={s.selectionTab}>
      <CheckboxOption
        description={
          translations.popupSelectionCheckboxNecessaryCookiesDescription
        }
        label={translations.popupSelectionCheckboxNecessaryCookiesLabel}
        required
      />
      <CheckboxOption
        description={translations.popupSelectionCheckboxPreferencesDescription}
        isChecked={preferencesState}
        label={translations.popupSelectionCheckboxPreferencesCookiesLabel}
        setPreferencesState={setPreferencesState}
      />
      <CheckboxOption
        description={
          translations.popupSelectionCheckboxStatisticsAnalyticsCookiesDescription
        }
        isChecked={checkedState}
        label={
          translations.popupSelectionCheckboxStatisticsAnalyticsCookiesLabel
        }
        setCheckedState={setCheckedState}
      />
      <CheckboxOption
        description={
          translations.popupSelectionCheckboxMarketingCookiesDescription
        }
        isChecked={marketingState}
        label={translations.popupSelectionCheckboxMarketingCookiesLabel}
        setMarketingCheckedState={setMarketingCheckedState}
      />
    </div>
  )
}

export default SelectionTab
