import React from "react"
import { CookieIcon } from "../../../../../common/Icons"
import s from "../TitleWithImg/TitleWithImg.module.scss"

const TitleWithImg = (props) => {
  const { translations } = props

  return (
    <div className={s.container}>
      <CookieIcon className={s.icon} />
      <h1 className={s.title}>{translations.bannerTitle}</h1>
    </div>
  )
}

export default TitleWithImg
