import { Link } from "gatsby"
import React, { useContext } from "react"

import { createLocalizedPagePath } from "../../utils/urls"
import { LocaleContext } from "../Layout"

export default function LocalizedProductLink({
  productGroupSlug,
  productSlug,
  ...props
}) {
  const { localePath, pages } = useContext(LocaleContext)
  const menuPath = createLocalizedPagePath(pages, localePath, "/menu/")

  if (productSlug) {
    return (
      <Link {...props} to={`${menuPath}${productGroupSlug}/${productSlug}/`} />
    )
  }

  return <Link {...props} to={`${menuPath}${productGroupSlug}/`} />
}
