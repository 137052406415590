import React from "react"

import s from "../PopupHeader/PopupHeader.module.scss"
import LocaleDropdown from "../../Banner/BannerHeader/components/LocaleDropdown/LocaleDropdown"
import TitleWithImg from "../../Banner/BannerHeader/components/TitleWithImg/TitleWithImg"

const PopupHeader = (props) => {
  const {
    currentLocale,
    langCode,
    pagePathsByLangCode,
    setCurrentLocale,
    translations,
  } = props

  return (
    <header className={s.popupHeader}>
      <div className={s.popupHeaderContainer}>
        <TitleWithImg translations={translations} />
        <LocaleDropdown
          currentLocale={currentLocale}
          langCode={langCode}
          pagePathsByLangCode={pagePathsByLangCode}
          setCurrentLocale={setCurrentLocale}
        />
      </div>
      <p className={s.paragraph}>{translations.bannerDescription}</p>
    </header>
  )
}

export default PopupHeader
