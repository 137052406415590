import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { CheckMarkIcon } from "../../../../../common/Icons"
import s from "../CheckboxOption/CheckboxOption.module.scss"

const CheckboxOption = (props) => {
  const {
    description,
    isChecked,
    label,
    required,
    setCheckedState,
    setMarketingCheckedState,
    setPreferencesState,
  } = props

  const [checked, setChecked] = useState(null)

  const handleClick = () => {
    if (required) return

    setChecked(!checked)

    if (setCheckedState) {
      setCheckedState(!checked)
    }

    if (setMarketingCheckedState) {
      setMarketingCheckedState(!checked)
    }

    if (setPreferencesState) {
      setPreferencesState(!checked)
    }
  }

  useEffect(() => {
    if (required || isChecked) {
      setChecked(true)
    }

    return () => {
      setChecked(null)
    }
  }, [])

  return (
    <div className={s.customCheckbox}>
      <label className={s.customCheckboxContainer}>
        <input
          onChange={handleClick}
          style={{ display: "none" }}
          type="checkbox"
        />
        <div
          className={
            checked && !required
              ? classNames(s.customCheckboxInput, s.customCheckboxInputChecked)
              : required
              ? classNames(s.customCheckboxInput, s.customCheckboxInputRequired)
              : s.customCheckboxInput
          }
        >
          {(checked || required) && <CheckMarkIcon />}
        </div>
        <h2>{label}</h2>
      </label>
      <p className={s.description}>{description}</p>
    </div>
  )
}

export default CheckboxOption
