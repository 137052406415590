import React from "react"
import s from "../PopupBody/PopupBody.module.scss"
import CookiePolicyTab from "./components/CookiePolicyTab/CookiePolicyTab"
import PrivacyPolicyTab from "./components/PrivacyPolicyTab/PrivacyPolicyTab"
import SelectionTab from "./components/SelectionTab/SelectionTab"
import TabNav from "./components/TabNav/TabNav"

const PopupBody = (props) => {
  const {
    checkedState,
    currentLocale,
    marketingState,
    preferencesState,
    preopenTab,
    setMarketingCheckedState,
    selectTab,
    selectedTab,
    setPreferencesState,
    setCheckedState,
    translations,
  } = props

  return (
    <div className={s.popupBody}>
      <TabNav
        preopenTab={preopenTab}
        selectTab={selectTab}
        translations={translations}
      >
        {selectedTab === "selection" && (
          <SelectionTab
            checkedState={checkedState}
            marketingState={marketingState}
            preferencesState={preferencesState}
            setCheckedState={setCheckedState}
            setMarketingCheckedState={setMarketingCheckedState}
            setPreferencesState={setPreferencesState}
            translations={translations}
          />
        )}
        {selectedTab === "cookie" && (
          <CookiePolicyTab translations={translations} />
        )}
        {selectedTab === "privacy" && (
          <PrivacyPolicyTab
            currentLocale={currentLocale}
            translations={translations}
          />
        )}
      </TabNav>
    </div>
  )
}

export default PopupBody
