import classNames from "classnames"
import React, { useRef, useContext, useState } from "react"
import { useClickAway } from "react-use"
import { CheckMarkIcon, DropdownArrowIcon } from "../../../../../common/Icons"
import { LocaleContext } from "../../../../../Layout"

import s from "../LocaleDropdown/LocaleDropdown.module.scss"

export default function LocaleDropdown(props) {
  const { currentLocale, setCurrentLocale } = props
  const [open, setOpen] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(currentLocale)
  const {
    header: { locales },
  } = useContext(LocaleContext)
  const langByLangCode = locales.find(
    (locale) => locale.langCode === selectedLocale
  )
  const [currentLang, setCurrentLang] = useState(langByLangCode)
  const offClickContainerRef = useRef()

  const changeLang = (selectedLocale) => {
    setCurrentLocale(selectedLocale)
    setSelectedLocale(selectedLocale)
    setOpen(false)
  }

  useClickAway(offClickContainerRef, () => setOpen(false))

  return (
    <div className={s.localeDropdown} ref={offClickContainerRef}>
      <button
        className={classNames(s.dropdownTrigger, {
          [s.cyrillic]: selectedLocale === "ru",
        })}
        onClick={() => setOpen(!open)}
      >
        {langByLangCode.label}{" "}
        <DropdownArrowIcon
          className={classNames(s.dropdownArrow, { [s.open]: open })}
        />
      </button>
      {open ? (
        <ul className={s.dropdownContent}>
          {locales.map(({ label, langCode }) => (
            <li
              className={classNames(s.langButton, {
                [s.cyrillic]: langCode === "ru",
              })}
              key={label}
              onClick={() => changeLang(langCode)}
            >
              <div>
                {label}{" "}
                {selectedLocale === langCode ? (
                  <CheckMarkIcon className={s.dropdownCheck} />
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}
