import React from "react"

import s from "./HamburgerButton.module.scss"

export default function HamburgerButton({ onClick }) {
  return (
    <div className={s.hamburgerButton}>
      <div
        aria-label="navigation"
        className={s.hamburger}
        id="hamburger"
        onClick={onClick}
        onKeyDown={onClick}
        role="button"
        tabIndex={0}
      >
        <div className={s.line} />
        <div className={s.line} />
        <div className={s.line} />
      </div>
    </div>
  )
}
