import React from "react"
import s from "../PopupFooter/PopupFooter.module.scss"

const PopupFooter = (props) => {
  const { cookiesAll, cookiesSelection, saveCookies, translations } = props

  return (
    <footer className={s.popupFooter}>
      <div className={s.buttonsContainer}>
        <button
          className={s.buttonAgreeAll}
          onClick={() => saveCookies(cookiesAll)}
        >
          <span>{translations.popupButtonAgreeToAll}</span>
        </button>
        <button
          className={s.buttonAgreeSelection}
          onClick={() => saveCookies(cookiesSelection)}
        >
          <span>{translations.popupButtonAgreeToSelection}</span>
        </button>
      </div>
    </footer>
  )
}

export default PopupFooter
