import classNames from "classnames"
import React, { useContext } from "react"

import { WordMark } from "../components/common/Icons"
import { isExternal } from "../utils/urls"
import LocalizedLink from "./common/LocalizedLink"
import LocalizedProductLink from "./common/LocalizedProductLink"
import s from "./Footer.module.scss"
import { LocaleContext } from "./Layout"

export default function Footer({
  langCode,
  productGroups,
  setIsCookieConsentEditOpen,
  setPreopenTab,
}) {
  const { footer } = useContext(LocaleContext)

  return (
    <footer
      className={classNames(s.footer, { [s.cyrillic]: langCode === "ru" })}
    >
      <section className={s.mainSection}>
        <div className={s.content}>
          <div className={s.menuInfoColumnWrapper}>
            <div className={s.menuColumnWrapper}>
              <div className={s.menuColumn}>
                <h2 className={s.linkHeading}>{footer.menu.title}</h2>
                <ul>
                  {productGroups.slice(0, 4).map(({ name, slug }) => (
                    <ProductGroupLinkItem
                      description={name}
                      key={slug}
                      productGroupSlug={slug}
                    />
                  ))}
                </ul>
              </div>
              <div className={s.menuColumn}>
                <h2 className={s.linkHeading}>&nbsp;</h2>
                <ul>
                  {productGroups.slice(4).map(({ name, slug }) => (
                    <ProductGroupLinkItem
                      description={name}
                      key={slug}
                      productGroupSlug={slug}
                    />
                  ))}
                </ul>
              </div>
            </div>
            <div className={s.infoColumn}>
              <h2 className={s.linkHeading}>{footer.information.title}</h2>
              <ul>
                {footer.information.links.map((link) => {
                  if (link.url === "/cookie-settings") {
                    return (
                      <li
                        className={s.cookieConsentListItem}
                        key={link.description}
                      >
                        <a
                          onClick={() => {
                            setIsCookieConsentEditOpen(true)
                            setPreopenTab("selection")
                          }}
                          role="button"
                        >
                          {link.description}
                        </a>
                      </li>
                    )
                  }

                  if (link.url === "/privacy-policy") {
                    return (
                      <li
                        className={s.cookieConsentListItem}
                        key={link.description}
                      >
                        <a
                          onClick={() => {
                            setIsCookieConsentEditOpen(true)
                            setPreopenTab("privacy")
                          }}
                          role="button"
                        >
                          {link.description}
                        </a>
                      </li>
                    )
                  }

                  return <LinkItem key={link.description} {...link} />
                })}
              </ul>
            </div>
          </div>

          <div className={s.careerSocialWrapper}>
            <div>
              <h2 className={s.linkHeading}>{footer.job.title}</h2>
              <ul>
                {footer.job.links.map((link) => (
                  <LinkItem key={link.description} {...link} />
                ))}
              </ul>
            </div>
            <div className={s.socialColumn}>
              <h2 className={s.linkHeading}>{footer.socialNetworks.title}</h2>
              <ul>
                {footer.socialNetworks.links.map((link) => (
                  <LinkItem key={link.description} {...link} />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className={s.copyrightSection}>
        <div className={s.content}>
          <div className={s.column}>
            <WordMark className={s.copyrightHeading} />
            {footer.copyright.texts.map((text) => (
              <p
                className={s.copyrightText}
                dangerouslySetInnerHTML={{ __html: text }}
                key={text}
              />
            ))}
          </div>
        </div>
      </section>
    </footer>
  )
}

const ProductGroupLinkItem = ({ productGroupSlug, description }) => (
  <li className={s.listLinkItem}>
    <LocalizedProductLink productGroupSlug={productGroupSlug}>
      {description}
    </LocalizedProductLink>
  </li>
)

const LinkItem = (props) => (
  <li className={s.listLinkItem}>
    {isExternal(props.url) ? (
      <ExternalLink {...props} />
    ) : (
      <InternalLink {...props} />
    )}
  </li>
)

const InternalLink = ({ url, description }) => (
  <LocalizedLink to={url}>{description}</LocalizedLink>
)

const ExternalLink = ({ url, description }) => (
  <a href={url} key={description} rel="noopener noreferrer" target="_blank">
    {description}
  </a>
)
