import React, { useState } from "react"
import classNames from "classnames"
import s from "../TabNav/TabNav.module.scss"

const TabNav = (props) => {
  const { children, preopenTab, selectTab, translations } = props
  const [activeTab, setActiveTab] = useState(
    preopenTab ? preopenTab : "selection"
  )

  return (
    <nav>
      <ul className={s.tabNav}>
        <li
          className={classNames(s.tabNavItem, {
            [s.active]: activeTab === "selection",
          })}
          onClick={() => {
            selectTab("selection")
            setActiveTab("selection")
          }}
        >
          <span>{translations.popupTabLabelSelection}</span>
        </li>
        <li
          className={classNames(s.tabNavItem, {
            [s.active]: activeTab === "cookie",
          })}
          onClick={() => {
            selectTab("cookie")
            setActiveTab("cookie")
          }}
        >
          <span>{translations.popupTabLabelCookiePolicy}</span>
        </li>
        <li
          className={classNames(s.tabNavItem, {
            [s.active]: activeTab === "privacy",
          })}
          onClick={() => {
            selectTab("privacy")
            setActiveTab("privacy")
          }}
        >
          <span>{translations.popupTabLabelPrivacyPolicy}</span>
        </li>
      </ul>
      {children}
    </nav>
  )
}

export default TabNav
