import classNames from "classnames"
import React from "react"

import { SearchIcon } from "../common/Icons"
import s from "./SearchBar.module.scss"

export const variations = {
  header: "headerVariation",
  hero: "heroVariation",
}

export default function SearchBar({
  onChange,
  onSearchClick,
  placeholder,
  variation,
  value,
}) {
  const iconProps = onSearchClick
    ? {
        onClick: handleSearchAction,
        onKeyDown: handleSearchAction,
        "aria-label": placeholder,
        role: "button",
        tabIndex: 0,
      }
    : {}
  const inputProps = onSearchClick
    ? {
        onKeyUp: handleEnterPress,
      }
    : {}

  return (
    <div className={classNames(s.searchBar, s[variation])}>
      <input
        aria-label={placeholder}
        className={s.input}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        value={value}
        {...inputProps}
      />
      <SearchIcon className={s.icon} {...iconProps} />
    </div>
  )

  function handleEnterPress(e) {
    if (e.keyCode === 13) {
      handleSearchAction()
    }
  }

  function handleSearchAction() {
    onSearchClick(value)
  }
}
